/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";

:root {
  --header-height: 2.8rem;
  --nav-width: 68px;
  --first-color: #4723d9;
  --first-color-light: #afa5d9;
  --white-color: #f7f6fb;
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;
}

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: var(--header-height) 0 0 0;
  /* negates the default 8px margins */
  /* padding: 0 0.5rem; */
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
  /* padding-top: calc(var(--header-height) + 1rem); */
  /*display: grid;
  grid-template-rows: 10vh auto;*/
}

.fa-solid,
.fa-regular,
.fa-times {
  color: #3ded97;
  /* Seafoam Color */
}

.bi {
  font-size: 1rem;
}

a {
  text-decoration: none;
}

input:required {
  background-color: yellow;
}


.header {
  width: 100%;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem; /* Adjusted padding for general use */
  background-color: var(--white-color);
  transition: 0.5s;
}
.mobile-header{
  display: none;
}
.mobile-nav{
  display: none;
}
@media (max-width: 767px) {
  .header {
    display: none;
  }
  .mobile-nav{
    display: block;
  }
  
  
  .mobile-header {
    display: block;
    width: auto;
    /* background-color: #0810f2; */
    border-bottom: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    color:whitesmoke;
    position: relative;
  }
  /* .floating-icon {
    position: relative;
    top: 60px; 
    right: 20px; 
    cursor: grab; 
    border-radius: 50%; 
    background-color: rgba(255, 255, 255, 0.8); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    transition: all 0.3s; 
    z-index: 1000;
    display: inline-block;
  }
  
  .floating-image {
    width: 39px; 
    height: 45px; 
    border-radius: 50%; 
  }
  .tooltip-toggle {
    display: none; 
  }
  
  .tooltip {
    visibility: hidden; 
    width: 140px;
    background-color: rgba(0, 0, 0, 0.75);
    color: white; 
    text-align: center; 
    border-radius: 4px; 
    padding: 5px; 
    position: absolute; 
    bottom: 125%;
    transform: translateX(-50%); 
    opacity: 0; 
    transition: opacity 0.3s; 
    z-index: 100; 
    
  }
  
  .tooltip-toggle:checked + .tooltip {
    visibility: visible;
    opacity: 1; 
     
  }  */
  
  /* .mobileHeaderLogo{
    width: 40px;
    border-radius: 50%;
    margin-left: 9px;
  } */
  /* Header logo image styling */
.header-logo img {
  max-height: 49px;
  width: auto;
  border-radius: 50%;
  margin-right: 5px;
}

/* Header logo container */
.header-logo {
  padding: 0 7px;
  display: flex;
  align-items: center;
}

/* Make logo text bold */
.header-logo span {
  font-weight: bold;
}

/* Flex container for top section (Hamburger and logo) */
.mobile-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: #0810f2;
  height: 88px;
}

/* Hamburger menu */
.hamburger-menu {
  margin-left: 10px;
}

/* Tooltip and account details container */
.tooltip-account-wrapper {
  display: flex;
  justify-content: flex-end; /* Aligns everything to the right */
  width: 100%;
  background-color: #F7F6FB;
}

/* Tooltip container */
.tooltip-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Aligns tooltip content to the right */
  /* background-color: grey; */
  padding: 10px;
  border-radius: 5px;
}

/* Tooltip styling */
.tooltip {
  /* background-color: #f9f9f99c; */
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
}

/* Account details */
.account-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background-color: white; */
  color: #000;
  padding: 10px;
  border-radius: 5px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .tooltip-account-wrapper {
    flex-direction: column;
    align-items: flex-end; /* Adjusts layout for smaller screens */
    width: 100%;
  }
}


  /* .hamburger-menu i {
    font-size: 2.5rem;
    cursor: pointer;
  } */
  /* .hamburger-menu {
    flex-grow: 0;
    
  } */
  .fas {
    line-height: 2.04167em;
  }
  
  /* .account-details {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: flex-start; 
    margin-left: auto;
  }

  
.account-details span, .user-details {
    margin-bottom: 2px; 
    flex: 1;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
    
  }
  
  .user-details {
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  } */
  
  .nav_link {
    display: flex;
  align-items: center; 
  text-decoration: none;
  color: inherit;
  }
  
  .mobile-header-bottom {
    width: 100%;
  }
/*   
  marquee {
    padding: 10px;
    width: auto;
    font-size: 0.8rem;
    color: #333;
    background-color: #e9ecef; 
  } */
  .mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; 
    height: auto; 
    background-color: #0810f2;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    z-index: 1500;
    overflow: auto;
  }
  
  .close-menu {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    z-index: 1010;  
  }
  
  .close-menu i {
    font-size: 24px;
    color: #fffdfd; 
  }
  
  
  
  .mobile-nav ul {
    list-style: none;
    padding: 39px;
  }
  
  .mobile-nav li a {
    display: flex;
    align-items: center;
    padding: 9px 35px;
    text-decoration: none;
    color: aliceblue;
  }
  .section-access {
    margin-top: 25px !important; 
    margin-bottom: 1px;
  }
  
  .mobile-nav li a .nav_icon {
    margin-right: 10px;
  }
  
  
  .mobile-nav.visible {
    display: block;
  }
  .mobile-nav p{
    margin-top: 0;
    margin-bottom: 0rem;
  }
  .nav_link {
    display: flex; 
    align-items: center;  
    text-decoration: none;  
    color: inherit;  
    padding: 10px;  
}

.nav_link i {
    margin-right: 10px;  
}

.nav_link p {
    margin: 0;  
}

.fa-solid {
    width: 20px;  
    text-align: center; 
}
  
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.header_img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.header_img img {
  width: 40px;
}

.nav-access {
  display: flex;
  flex-flow: row nowrap;
  padding: 1rem 1rem 0 1rem;
  gap: 1rem;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--first-color);
  padding: 0.5rem 1rem 0 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_logo {
  margin-bottom: 2rem;
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
}

.nav_logo-name {
  color: var(--white-color);
  font-weight: 700;
}

.nav_link {
  position: relative;
  color: var(--first-color-light);
  transition: 0.3s;
  text-decoration: none;
}

.nav_link:hover {
  color: var(--white-color);
}

.nav_icon {
  font-size: 1.25rem;
}

.show {
  left: 0;
}

/* .body-pd {
  padding-left: calc(var(--nav-width) + 4rem);
} */
@media (min-width: 992px) {
  .body-pd {
    padding-left: calc(var(--nav-width) + 4rem);
  }
}

.active {
  color: var(--white-color);
}

.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color);
}
/* @media (max-width: 992px) {
  .main-panel {
    padding: 0 1rem;
  }

  .row {
    flex-direction: column;
    align-items: center;
  }
} */
/* .col-lg-6 img {
  margin-top: -50px;
} */

/* .main-panel {
    padding: 0.25rem;
    display: grid;
    padding-top: calc(var(--header-height) + 1rem);
} */
.main-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  overflow-y: hidden;
}

.height-100 {
  height: 100vh;
}

.height-90 {
  height: 90vh;
}
.height-93{
  height: 93vh;
}
.height-80 {
  height: 80vh;
}

.height-60 {
  height: 60vh;
}

.height-50 {
  height: 50vh;
}

/* .card {
    border-radius: 0.5rem;
    height: 100%; 
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 100;
} */

/* .card-shadow {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
}

.card {
  justify-content: center;
  align-content: center;
  width: 100%;
}

.dashboard {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    "col-1"
    "col-2"
    "col-3"
    "col-4"
    "col-5 col-5"
    "col-7 col-7"
    "col-6 col-6";
}
@media (min-width: 768px) {
  .dashboard {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "col-1 col-2 col-3 col-4"
      "col-5 col-5 col-6 col-6"
      "col-7 col-7 col-6 col-6";
  }
}
@media (max-width: 767px) {
  .dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.dashboard .col-1 {
  grid-area: col-1;
  width: 20%;
}

.dashboard .col-2 {
  grid-area: col-2;
  width: 20%;
}

.dashboard .col-3 {
  grid-area: col-3;
  width: 20%;
}

.dashboard .col-4 {
  grid-area: col-4;
  width: 40%;
} */

.config {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "col-1 col-2 col-3";
  /* gap: 5rem; */
}

.upload {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "col-1 col-2";
  gap: 1rem;
}

.filechoose-area,
.filedrop-area {
  height: 40%;
  width: 75%;
  margin: 0 auto;
  margin-top: 1rem;
  text-align: center;
  overflow: hidden;
  flex-wrap: wrap;
}

.filechoose-area {
  padding-top: 20;
  border: none;
}

.filedrop-area {
  border: 0.2rem dashed lightgray;
  border-radius: 1rem;
}

.filechoose-area:hover,
.filedrop-area:hover {
  cursor: pointer;
}

.filedrop-area h2 {
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: normal;
  font-family: sans-serif;
  line-height: 5rem;
  color: darkslategray;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: contents;
}

#fileselectionmsg {
  font-size: smaller;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.kpi-card {
  overflow: hidden;
  border: none;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  float: left;
  padding: 1rem;
  margin-left: 0.5em;
  margin-top: 0.5em;
  align-content: flex-start;
  justify-content: space-evenly;
  gap: 1rem;
}

.kpi-card-header {
  justify-content: flex-start;
  font-size: 150%;
  font-weight: bolder;
  width: -webkit-fill-available;
}

.kpi-card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.kpi-card-content-config {
  font-size: xx-large;
  font-weight: bolder;
}

.kpi-text {
  display: flex;
  flex-wrap: wrap;
  font-size: 150%;
  padding: 0.1rem;
  justify-content: flex-start;
  align-content: center;
}

.value-trend {
  display: flex;
  flex-wrap: row wrap;
  justify-content: center;
}

.kpi-value {
  display: flex;
  flex-wrap: wrap;
  font-size: 150%;
  font-weight: bolder;
  padding: 0.1rem;
  align-content: center;
}

.card-text {
  font-size: 3rem;
  font-weight: bold;
}

.green-percent {
  color: green;
}

.cardDescription-text {
  font-size: 1.5rem; 
}




.icon {
  display: flex;
  flex-wrap: wrap;
  font-size: 150%;
  padding: 0.1rem;
  justify-content: flex-end;
  align-content: center;
}

.form-error-msg {
  color: red;
  font-weight: bold;
  font-style: italic;
}

.form-hidden {
  opacity: 0;
}

.form-control-lg {
  max-width: 359px;
  width: 100%;
}


.tblcontainer {
  flex: 1 1 100%;
  flex-wrap: wrap;
  height: 30%;
  width: 100%;
  overflow-x: hidden;
  border: none;
  justify-content: flex-start;
}

/* .scrollable {
    width: 100%;
    display: flex;
    overflow-y: auto;
} */

/* tbody, td, tfoot, th, thead, tr { font-size: smaller; } */

.paddingBetweenCols td,
th {
  padding: 0 1.5rem;
}

.previewchosen {
  border: none;
  flex-wrap: wrap;
  align-content: flex-start;
}

.previewchosen > table {
  border-collapse: collapse;
  border-spacing: 0.5rem;
  font-size: smaller;
}

.previewchosen > table > tbody {
  max-height: 10%;
  overflow: auto;
}

.previewchosen > table > tbody > tr:nth-child(odd) {
  background-color: #dddddd;
}

.previewchosen > table > tbody > tr > td {
  text-align: left;
}

#uploadlog {
  justify-content: center;
}

#uploadlog,
th,
td {
  border-collapse: collapse;
}

#uploadlogbody {
  border: 0.1rem solid darkslategray;
}

.filechoice {
  flex-wrap: wrap;
  border: none;
}

.filechoice > label {
  background-color: #7f9ccb;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px ridge black;
  font-size: 0.8rem;
  height: auto;
}

.filechoice > label:hover {
  background-color: #2d5ba3;
  color: white;
}

.filechoice > label:active {
  background-color: #0d3f8f;
  color: white;
}

.upload-info {
  margin: 0 auto 0.5rem auto;
  font-size: x-small;
  justify-content: space-between;
  flex-direction: row;
  width: 75%;
  border: none;
  flex-wrap: wrap;
}

.file-upload-info {
  flex-wrap: wrap;
  border: none;
  align-content: flex-start;
}

.file-upload-size-info {
  flex-wrap: wrap;
  border: none;
  align-content: flex-end;
}

.upload-action {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 1rem 0 0;
}

.box_button {
  font-weight: 300;
  background-color: #cacbcc;
  color: #515353;
  padding: 1rem 2rem;
  margin: 0;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.file_action_btn {
  padding: 0 2.5rem;
  margin: 1rem;
}

::placeholder {
  font-size: small;
  font-style: italic;
}

#previewimage {
  width: 1;
  height: auto;
  opacity: 0;
}

.file-preview {
  opacity: 1;
  width: 1rem;
  height: auto;
}


#worldmap {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.dot-marker {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}

.hq {
  color: #FF5733 !important;
}

.dc {
  color: #2E86C1 !important;
}

.warehouse {
  color: #900C3F !important;
}

.port {
  color: #117A65 !important;
}

.freight_forwarder {
  color: #154360 !important;
}

.plant {
  color: #4A235A !important;
}


@media (min-width: 760px) {
  body {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    /* padding-left: calc(var(--nav-width) + 0rem); */
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
    
  }

  .header_img {
    width: 40px;
    height: 40px;
  }

  .header_img img {
    width: 45px;
  }

  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
  }

  .show {
    width: calc(var(--nav-width) + 156px);
  }

  /* .body-pd {
    padding-left: calc(var(--nav-width) + 188px);
  } */

  .mainpanel {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    grid-template-areas: initial;
  }

  .card {
    grid-area: initial !important;
  }
  .col-md-12{
    width: 100%;
    height: 75vh;
  }
}

/* New CSS */

/* #formbtn {
    border-radius: 25px;
    font: "Nunito", sans-serif;
} */

#custom-card {
  width: 300px;
  border-radius: 18px;
  margin: 0 auto;
}

 .modal-dialog {
  position: center;
  top: 10%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
} 

.modal-content {
  overflow-y: auto;
}

/* .modal-dialog {
  display: flex;
  align-items: center; 
  justify-content: center; 
  min-height: calc(100vh - 60px); 
}

.modal-content {
  width: 100%; 
} */



#openPopupButton {
  margin-bottom: 10px;
  border-radius: 25px;
  padding: 9px;
  font: "Nunito", sans-serif;
  width: auto;
  margin-left: 11px;
}


#dataTable {
  width: 100%;
  margin-top: 20px;
  table-layout: fixed;
}

.modal-content {
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  overflow-y: auto;
}

.modal-header {
  background-color: #198754;
  color: white;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-body {
  background-color: rgba(248, 249, 250, 0.8);
}

.modal-footer {
  background-color: rgba(13, 110, 253, 0.8);
  border-top: 1px solid #dee2e6;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  justify-content: space-between;
}


.map-table th, .map-table td {
  border: 1px solid #ddd;
  padding: 12px;
}

/* .btn-primary {
  background-color: rgba(0, 123, 255, 0.8);
  border-color: rgba(0, 123, 255, 0.8);
  min-width: 120px;
  border-radius: 25px;
}

.btn-primary:hover {
  background-color: rgba(0, 123, 255, 0.6);
  border-color: rgba(0, 123, 255, 0.6);
} */


#dataTable th,
#dataTable td {
  white-space: normal;
  word-wrap: break-word;
  max-width: 150px;
}

#dataTable th {
  background-color: #198754;
  color: white;
  text-align: center;
}

#dataTable th.scrollable-column {
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
}

/* .position-relative {
    position: relative;
} */

.password-input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.password-input-container input {
    flex: 1;
    padding-right: 36px; 
} 

.position-absolute {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
} 

.end-icon {
  cursor: pointer;
  margin-left: -30px;
  z-index: 1;
}

#dashboard_Button {
  margin-top: 20px;
  width: 244px;
}

/* Responsive styling for small screens */
@media (max-width: 768px) {
  #dashboard_Button {
      width: 100%; /* Full width for small screens */
  }
}

.section-access {
  margin-top: 250px;
  margin-bottom: 300px;
}

#frg_pwd {
  margin-top: 8px;
}

#cards {
  margin-top: 20px;
  margin-bottom: 20px;
}

#map_card {
  height: calc(80vh - 4rem);
}

#alerts_topDetails,
#alerts_top {
  background-color: #198754;
  word-wrap: break-word;
  color: white;
}
#alertCondition{
  border-radius: 25px;
  padding: 9px;

}
.btn-close-custom{
  background: none; 
  border: none; 
  padding: 0;
}

.btn-close-custom img{
  width: 24px; 
  height: 24px;
}

.select-wrapper {
  position: relative;
  display: block;
  width: 100%;
}

.select-wrapper select {
  -webkit-appearance: none; 
  -moz-appearance: none;    
  appearance: none;
  padding-right: 30px;      
  width: 100%;             
}

.select-wrapper i {
  pointer-events: none;   
  z-index: 10;              
  color: #495057;           
}


.fixed-width-card {
  max-width: 100%; 
}

/* *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'poppins', sans-serif;
} */

.nav-side{
  width: 100%;
  height: 100vh;
  
  background-position: center;
  background-size: cover;
}
.side-nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;;
  background-color: #0810f2;
  transition: width 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center; 
  z-index: 5000;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  padding: 10px;
  margin-top: 50px; 
  transition: margin-top 0.5s; 
}
.margin-zero {
  margin: 0;
}

.logo h2{
  
}
.logo-img {
  width: 31px;
  border-radius: 50%;
}
.logo div, .logo h2 {
  display: none; 
}
.side-nav.expanded .logo div, .side-nav.expanded .logo h2  {
  display: block; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  transition: margin-top 0.5s; 
  font-size: 28px;
  font-weight: 600;
  white-space: nowrap;
  margin-top: 4px;
  color: whitesmoke;
}




/* .body-pd {
  padding-left: calc(var(--nav-width) + 1rem);
} */

.side-nav.expanded .logo-img{
  width: 40px;

}
.side-nav.expanded .company_logo{
  width: 100%;
  backdrop-filter: blur(5px);

}
.side-nav.expanded ul li i{
  margin-right: 10px;
}
.side-nav.expanded ul li{
  justify-content: flex-start;
}
.side-nav.expanded p{
  margin-top: 0rem;
  margin-bottom: 0rem;
}

#sidebar_ul{
  list-style: none;
  padding: 2px 5px;
  margin-top: 25px;
}
/* ul li{
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
} */
.nav_list ul{
  list-style: none;
  padding: 1rem 15px;
}

.nav_list ul li{
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nav_list ul li i{
  width: 30px;
  margin-right: 9px;
}

.nav_list ul li p{
  white-space: nowrap;
}

.toggle-button {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  transition: left 0.2s, transform 0.2s;
  cursor: pointer;
  z-index: 10; 
}
.side-nav.expanded .toggle-button {
  left: 10px;
  transform: translateX(0%);
}


.side-nav ul li p {
  color: white;
  transition: color 0.3s;  
}


.side-nav ul li p:hover {
  color: grey; 
}


.logout {
  margin-top: auto; 
  margin-bottom: 37px;
  margin-right: 22px;
  cursor: pointer;
}
.side-nav.expanded .logout{
  margin-right: 63px; 
}

.side-nav .logout p {
  color: white;
  transition: color 0.3s;  
  cursor: pointer;
}


.side-nav .logout p:hover {
  color: grey; 
}


#rowsPerPage {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.icon-centered, .icon-cell {
  text-align: center;
  width: 4%; 
  white-space: nowrap; 
}


.pagination-controls {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.pagination-controls select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.table-responsive {
  overflow-x: auto;
}

#paginationButton{
  padding: 2px;
  margin: 1px;
  min-width: 28px;
}

@media (max-width: 767px) {
  .side-nav {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1094px) {
  .body-pd{
    /* margin-left: 110px; */
    padding-left: calc(var(--nav-width) + 2rem);
  }
}

@media (min-width: 768px) and (max-width: 1665px) {
  .body-pd{
    margin-left: 100px;
  }
}
/* @media (min-width: 577px) and (max-width: 618px) {
  .mobile-nav{
    display: block;
  }
} */
/* @media (max-width: 992px) {
  .header{
    margin-top: 16px;
  }
} */
/* @media (min-width: 619px) and (max-width: 767px) {
  .side-nav {
    display: block;
  }
  .account-details {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: flex-start; 
    margin-left: auto;
  }
  
  .account-details span, .user-details {
    margin-bottom: 2px; 
    flex: 1;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
    
  }
  
  .user-details {
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  }
} */


#shipmentTable th,
#shipmentTable td{
  white-space: normal;
  word-wrap: break-word;
  max-width: 100%;
}

#shipmentTable th {
  background-color: #198754;
  color: white;
  text-align: center;
}




.dropdown {
  position: flex;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: auto; 
  left: auto;
  bottom: 0;
  min-width: 160px; 
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
 
}

.dropdown:hover .dropdown-menu{
  display: block; 
}

.icon-cellMore  {
  cursor: pointer; 
  text-align: center;
  width: 4px; 
  
}


#shipmentTable tbody{
    /* display: block; */
    max-height: 300px;
    overflow-y: auto;
}
#confirmationButton{
  margin:3px;
  
}

#shipmentContainer {
  max-height: calc(100vh - 250px); 
  overflow-y: auto;
  
  overflow-y: auto;
}


.fixed-header {
    position: sticky;
    top: 0;
    background-color: #fff; 
    z-index: 1;
}

#shipmentTable th.scrollable-column {
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
}



.option-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
  margin-bottom: 10px; 
}

.option-item {
  display: flex;
  align-items: center; 
  margin-right: 20px; 
  width: 200px; 
}
label {
  margin-left: 9px;
}
.option-item label {
  padding: 5px; 
}

.mt-5 {
  margin-top: 2rem !important;
 
}

#span-1 #span-2{
  vertical-align: bottom;
}

#span2{
  margin-left: 30px; 
  color: blue;
}

.track-label {
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.detail-label {
  font-size: 16px;
  margin-top: 14px;
}

/* .justify-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.mb-3 {
  margin-bottom: 1rem !important;
}
.status-heading {
  text-align: center;
}

.status {
  font-size: 38px; 
  font-weight: bold;
}

.date {
  font-size: 34px; 
}

.tracker-container {
  display: flex;
  justify-content: center; 
}

.tracker {
  display: flex;
  align-items: center;
  width: 50%;
}

.point {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ccc;
}

.line {
  flex: 1;
  height: 2px;
  background-color: #ccc; 
}

.completed {
  background-color: green; 
}

#iButton {
  margin-left: 10px; 
  color: #0d6efd; 
  transition: color 0.3s; 
}

#iButton:hover {
  color: #5289dc;
}
.filter-instructions {
  margin-bottom: 10px;
  font-weight: 25px; 
}
.radio-icon-container {
  display: flex;
  align-items: center;
}

.radio-icon-container mat-radio-group {
  display: flex;
  align-items: center;
}

.add-condition-button {
  display: flex;
  align-items: center;
  padding: 4px 6px;
  color: rgb(14, 13, 13);
  border: 0.01rem solid #000;
  border-color: #000;
  border-radius: 4px;
  cursor: pointer;
  color: #000;
  background-color: white;
  transition: background-color 0.3s ease;
}

.add-condition-button:hover {
  background-color: #d7d2d2; 
}

.add-condition-button i {
  margin-right: 8px; 
}


/* .tab-header .btn.active.showTravelHistory {
  background-color: rgb(254, 252, 252);
  color: black;
}

.tab-header .btn {
  color: black;
  border-radius: 20px; 
}

.tab-header .btn.active {
  background-color: rgb(0, 128, 0);
  color: black;
}




.tab-header {
  position: relative; 
  background-color: #f8f9fa; 
  border-bottom: 1px solid #dee2e6; 
}

.tab-header button {
  border: none;
  border-radius: 0;
  background-color: transparent; 
  color: #000; 
  padding: 10px 15px; 
  margin-right: 10px; 
  transition: color 0.3s ease; 
}

.tab-header button:hover {
  background-color: #e9ecef; 
}

.tab-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px; 
  background-color: #007bff; 
  width: 0; 
  transition: width 0.3s ease; 
}

.tab-header button.active::after {
  width: 100%; 
}

.tab-header button.active {
  font-weight: bold; 
  color: purple; 
} */
.location {
  display: flex;
  
}

.from-location, .to-location {
  flex-grow: 1;
}

.address {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.location-label {
  font-weight: bold;
  margin-bottom: 5px; 
}

.divider {
  width: 1px;
  background-color: #ccc;
  margin: 0 20px;
}

/* .history-item {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.history-item p {
  margin: 5px 0;
} */

.details {
  display: flex;
  justify-content: space-between;
}

.second-details {
  margin-left: 30px; 
}

/* .card-body {
  border-radius: 20px; 
  padding: 20px; 
  margin-bottom: 20px; 
} */

.card-body {
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  padding: 10px;
}

.history-header {
  background-color: #f0f0f0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px;
}

.history-details {
  display: flex;
  flex-direction: column;
}


.history-field {
  flex: 1;
  margin: 5px;
  font-weight: bold;
  text-align: center;
}

.history-value {
  flex: 1;
  margin: 5px;
  text-align: center; 
}

.card-body.scrollable {
  max-height: 300px; 
  overflow-y: auto; 
}

.history-item {
  margin-bottom: 20px;
}


.history-row {
  display: flex;
  align-items: center;
}
.track-label{
  color: #0d6efd;
}



.pagination-controls {
  display: flex;
  justify-content: space-between;
}

.pagination-info {
  display: flex;
}

#numberspan{
  margin-right: 42px;
}
.add-condition-button{
  margin-top: 11px;
}
#labelDynamicQuery{
  display: flex; 
  align-items: center; 
  white-space: nowrap;
  margin-bottom: 10px;
}
#DQlabel{
  margin-right: 10px;
  margin-left: 0px;
}

#ordersDQ{
  border: 1px solid #ced4da; min-width: 150px; max-width: 300px;
}
#ordersDQLabel{
  display: flex; align-items: center; white-space: nowrap;
}




.step-indicator {
  display: flex;
  align-items: center;
  font-size: 1.4em;
}

.step-indicator span {
  margin: 0 0px;
  
}

.step-indicator span.active {
  font-weight: bold;
  color: #007bff;
}

.step-content {
  margin-top: 20px;
}

#stepsSpan{
  font-size: 1.2em;
}

.upload-box {
  border: 2px dashed #007bff;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.upload-box.dragover {
  background-color: #e9ecef;
}

.upload-box p {
  margin: 0;
  font-size: 1.2em;
  color: #007bff;
}

.file-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 0.9em;
  color: #6c757d;
  max-height: 100px;
}

.file-info p {
  margin: 0;
}

.right {
  text-align: right;
  flex-grow: 1;
}

.file-error {
  color: #dc3545;
}

.file-table {
  width: 50%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed; 
}

.file-table th,
.file-table td {
  border: 1px solid #ddd;
  padding: 8px;
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
}

.file-table th {
  background-color: #f2f2f2;
  position: sticky; 
  top: 0; 
  z-index: 1;
}
.file-table td {
  max-width: 200px; 
}

.file-table tbody {
  display: block; 
  max-height: 150px; 
  overflow-y: auto; 
}

.file-table thead,
.file-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; 
}
.file-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.file-table tr:hover {
  background-color: #ddd;
}

.upload-label-wrapper {
  margin-top: 20px;
}

.upload-label {
  font-weight: bold;
}

.data-mapping-name {
  display: flex;
  align-items: center;
  /* margin-top: 20px;
  margin-bottom: 20px; */
}

.data-mapping-name label {
  margin-right: 10px;
}

.data-mapping-name input {
  flex-grow: 1;
  /* padding: 5px; */
  max-width: 27%;
}

.main-selection {
  /* margin-top: 20px; */
}

.main-selection label {
  margin-right: 10px;
}

.main-selection select {
  width: 200px; 
  padding: 5px;
}





.file-review-detail-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed; 
}

.file-review-detail-table th,
.file-review-detail-table td {
  border: 1px solid #ddd;
  padding: 15px;
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
}

.file-review-detail-table th {
  background-color: #f2f2f2;
  position: sticky; 
  top: 0; 
  z-index: 1; 
}

/* .file-review-detail-table tbody {
  display: block; 
  height: 200px; 
  overflow-y: auto; 
} */

.file-review-detail-table thead,
.file-review-detail-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; 
}

.file-review-detail-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.file-review-detail-table tr:hover {
  background-color: #ddd;
}

/* .switch .slider {
  display: inline-block;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
} */



.map-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
}

.map-table th,
.map-table td {
  border: 1px solid #ddd;
  padding: 15px;
  overflow: hidden;
  text-overflow: ellipsis; 
  white-space: nowrap; 
}

.map-table th {
  background-color: #f2f2f2;
  position: sticky; 
  top: 0;
  z-index: 1; 
}

.map-table tbody {
  display: block;
  height: 200px; 
  overflow-y: auto; 
}

.map-table thead,
.map-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; 
}
#mspOptions2{
  min-width: 332px;
}
.btn-warning {
  margin-top: 10px;
}

.file-table, .map-table, .file-review-table {
  width: 60%;
  border-collapse: collapse;
  margin-top: 10px;
}

.file-table th, .file-table td,
.map-table th, .map-table td,
.file-review-table th, .file-review-table td {
  border: 1px solid #ddd;
  padding: 16px;
}

.file-table th, .map-table th, .file-review-table th {
  background-color: #f2f2f2;
}

#clearButton{
  margin-top: 10px;
}

.map-table{
  min-height: 10px;
  overflow-y: auto;
  width: calc(100% - 1.1em);
  width: 90%;
  margin-top: 20px;
  margin-bottom: 24px;
  max-height: 100px;
}
.detail-table-wrapper {
  margin-top: 20px;
}

.file-review-detail-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed; 
}

.file-review-detail-table th,
.file-review-detail-table td {
  border: 1px solid #ddd;
  padding: 15px;
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
}

.file-review-detail-table th {
  background-color: #f2f2f2;
  position: sticky; 
  top: 0; 
  z-index: 1; 
}

.file-review-detail-table tbody {
  display: block; 
  min-height: 50px; 
  max-height: 100px;
  overflow-y: auto; 
}

.file-review-detail-table thead,
.file-review-detail-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; 
}

.file-review-detail-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.file-review-detail-table tr:hover {
  background-color: #ddd;
}


.table-container {
  width: 100%;
  overflow-x: auto;
}

.expanded-row {
  border-bottom: none;
  max-height: 20px;
  overflow-y: scroll;
}

.expanded-cell {
  display: inline-block;
  margin-bottom: 8px;
  padding: 8px 0px 8px 0px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.expanded-cell.has-content {
  border-bottom: 1px solid #626363;
}


#reviewCaption{
  margin-top: 11px;
}

#fileReviewTable {
  width: 100%;
  border-collapse: collapse;
}

#fileReviewTable th,
#fileReviewTable td {
  border: 1px solid #ddd;
  padding: 15px;
  white-space: nowrap; 
  min-width: 150px;
}

#fileReviewTable th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f2f2f2;
}
#tableContainer{
  min-height: 350px;
  max-height: 360px
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 850px){
  /* .modal-header {
    width:50px;
  } */
  .filter-instructions{
    width: 50px;
  }
  #ordersDQLabel{
    word-break: break-word; 
    overflow-wrap: break-word;
  }
}




.button-container {
  width: 1200px;
  max-width: 100%;
}

.heading-container {
  width: 1200px; 
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.administratorHeading {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  text-align: flex-start;
  width: 100%; 
  margin-top: 5rem;
  margin-left: 40px;
}





.square-button {
  width: 280px; 
  height: 200px;
  margin: 40px; 
  display: flex;
  justify-content: center; 
  align-items: center; 
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  box-sizing: border-box;
  background-color: #0edceb;
  color: white;
  border-radius: 10px;
  text-transform: uppercase; 
}

.square-button strong {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
  width: 100%; 
}

@media (max-width: 992px) {
  .button-container {
      width: 600px; 
  }

  .square-button {
      width: 180px;
      height: 180px;
  }
}

@media (max-width: 768px) {
  .button-container {
      width: 100%; 
  }

  .square-button {
      width: calc(33.33% - 20px);
      height: auto;
  }
}

@media (max-width: 576px) {
  .square-button {
      width: calc(100% - 20px);
      height: 150px; 
      font-size: 1rem;
  }
}

.expandItems{
  color: #0d6efd;
}



.nav-access {
  position: relative;
}

.tooltip-container {
  position: relative;
}

#user-tooltip {
  visibility: hidden;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 100;
}

.tooltip-container:hover #user-tooltip {
  visibility: visible;
  opacity: 1;
}

.multiselect-input {
  display: inline-block;
  width: 100%;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #ced4da;
  padding: 0.5rem;
  box-sizing: border-box;
}

.multiselect-input[size] {
  overflow-y: auto;
}

.multiselect-input option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.report-dropdown {
  width: auto;
  min-width: 368px;
  font-size: 1rem;
}


.d-flex.gap-2 > * {
  margin-right: 0.5rem;
}


#generateReportButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}


@media (max-width: 667px) {
  .d-flex.gap-2 {
      flex-direction: column;
      align-items: flex-start;
  }

  .report-dropdown {
      width: 100%;
      max-width: none;
  }
}

.sell-through-insights {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tab-button {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
}

.tab-button.active {
  background-color: #1B6532;
  color: #fff;
  border-color: #1B6532;
}

.tab-button:not(.active):hover {
  background-color: #e9ecef;
}






/* #testOptions {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

#testOptions{
  margin-bottom: 10px;
} */

/* 
.option-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.option-item {
  margin-bottom: 10px;
  display: flex; 
  align-items: center; 
}

.option-item input[type="checkbox"] {
  margin-right: 8px; 
}

label {
  margin-left: 4px;
} */

/* .option-item {
  display: flex; 
  align-items: center; 
  margin-bottom: 10px; 
}

label {
display: inline-block;
  margin-left: 4px;
} */



/* .option-block {
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  cursor: pointer;
} */

/* .options-list {
  display: flex;
  flex-wrap: wrap;
} */




/* .option-item {
  flex: 0 0 calc(16.666% - 10px); 
  margin-right: 10px;
  margin-bottom: 10px;
} */


/* .option-item {
  display: flex;
  flex-direction: row; 
} */

/* .side-nav:hover{
  width: 200px;
}
.side-nav:hover .user div{
  display: block;
}
.side-nav:hover .user{
  width:100%;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
}
.side-nav:hover .star-img{
  display: block;
}
.side-nav:hover .user-img{
  margin: 0;
}

.side-nav:hover ul li p{
  display: block;
}
.side-nav:hover ul li i{
  margin-right: 10px;
}

.side-nav:hover ul li {
  justify-content: flex-start;
} */